@font-face {
    font-family: 'Heuristica';
    font-style: normal;
    font-weight: 400;
    src: url('../../../assets/fonts/heuristica/Heuristica-Regular.otf'); /* IE9 Compat Modes */
    
  }
  /* blue:"#171544",
  sand:"#A5835A",
  black:"#1E191A",
  dkgrey:"#8A8B93",
  mdgrey:"#BABAC3",
  ltgrey:"#DBDCDE",
  white:"#E7E6E6",
  orange:"#C95D33", */
  /* classes for inline links */
 a{
  text-decoration: none;
 }
  div.blueLink p a:visited, 
  div.blueLink p a:link{
    color:#171544;
    text-decoration: none;
  }
  div.blueLink p a:hover, 
  div.blueLink p a:focus{
    color:#A5835A;
    text-decoration: none;
  }
  /*  */
  div.sandLink p a:visited, 
  div.sandLink p a:link{
    color:#A5835A;
    text-decoration: none;
  }
  div.sandLink p a:hover, 
  div.sandLink p a:focus{
    color:#171544;
    text-decoration: none;
  }
   /*  */
   div.blackLink p a:visited, 
   div.blackLink p a:link{
     color:#1E191A;
     text-decoration: none;
   }
   div.blackLink p a:hover, 
   div.blackLink p a:focus{
     color:#171544;
     text-decoration: none;
   }
    /*  */
  div.dkgreyLink p a:visited, 
  div.dkgreyLink p a:link{
    color:#8A8B93;
    text-decoration: none;
  }
  div.dkgreyLink p a:hover, 
  div.dkgreyLink p a:focus{
    color:#A5835A;
    text-decoration: none;
  }
   /*  */
  div.mdgreyLink p a:visited, 
  div.mdgreyLink p a:link{
    color:#DBDCDE;
    text-decoration: none;
  }
  div.mdgreyLink p a:visited,
  div.mdgreyLink p a:focus{
    color:#A5835A;
    text-decoration: none;
  }
   /*  */
  div.ltgreyLink p a:visited, 
  div.ltgreyLink p a:link{
    color:#C95D33;
    text-decoration: none;
    
  }
  div.ltgreyLink p a:hover, 
  div.ltgreyLink p a:focus{
    color:#A5835A;
    text-decoration: none;
  }
   /*  */
  div.whiteLink p a:visited, 
  div.whiteLink p a:link{
    color:#E7E6E6;
    text-decoration: none;
  }
  div.whiteLink p a:hover, 
  div.whiteLink p a:focus{
    color:#A5835A;
    text-decoration: none;
  }
   /*  */
  div.orangeLink p a:visited, 
  div.orangeLink p a:link{
    color:#C95D33;
    text-decoration: none;
  }
  div.orangeLink p a:hover, 
  div.orangeLink p a:focus{
    color:#A5835A;
    text-decoration: none;
  }